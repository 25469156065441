<template>
  <div>
    <b-row>
      <b-col>
        <b-card no-body>
          <b-card-header>
            <div>
              <b-card-title>ข้อมูลตัวแทน</b-card-title>
            </div>
            <h4 class="m-0">
              <b-link @click="onClickEditAgent">
                แก้ไขข้อมูล
              </b-link>
            </h4>
          </b-card-header>
          <hr class="m-0">
          <b-card-body>
            <b-form-group
              label="ชื่อตัวแทน"
              label-for="ชื่อตัวแทน"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="ชื่อตัวแทน"
                :value="requestDetail.agentName"
                class="text-right text-white"
                plaintext
              />
            </b-form-group>
            <b-form-group
              label="ระดับตัวแทน"
              label-for="ระดับตัวแทน"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="ระดับตัวแทน"
                :value="accountType"
                class="text-right text-white"
                plaintext
              />
            </b-form-group>
            <b-form-group
              label="เว็บไซต์เกม"
              label-for="เว็บไซต์เกม"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="เว็บไซต์เกม"
                :value="requestDetail.website"
                class="text-right text-white"
                plaintext
              />
            </b-form-group>
            <b-form-group
              label="% รายได้จากค่าธรรมเนียม"
              label-for="ค่าธรรมเนียม"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="ค่าธรรมเนียม"
                :value="requestDetail.commissionRate || 'ยังไม่ได้กำหนด'"
                class="text-right"
                plaintext
              />
            </b-form-group>
            <b-form-group
              label="อีเมลสำหรับเรียกเก็บเงิน"
              label-for="อีเมลสำหรับเรียกเก็บเงิน"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="อีเมลสำหรับเรียกเก็บเงิน"
                :value="requestDetail.billingEmail || '-'"
                class="text-right text-white"
                plaintext
              />
            </b-form-group>
            <b-form-group
              label="ผู้ติดต่อ"
              label-for="ผู้ติดต่อ"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="ผู้ติดต่อ"
                :value="requestDetail.contactName || 'ไม่มีชื่อ'"
                class="text-right text-white"
                plaintext
              />
              <b-form-text class="text-right">
                {{ requestDetail.contactEmail }}
              </b-form-text>
            </b-form-group>
          </b-card-body>
          <EditAgentModal
            :agent-id="requestDetail.agentId"
            :is-request-approved="isApproved"
            @refetch="fetchData"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <SandboxDetail @refetch="fetchData" />
      </b-col>
      <b-col>
        <ProductionDetail />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <DownLinesTable />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: {
    EditAgentModal: () => import('./EditAgentModal.vue'),
    SandboxDetail: () => import('./components/SandboxDetail.vue'),
    ProductionDetail: () => import('./components/ProductionDetail.vue'),
    DownLinesTable: () => import('./DownLinesTable.vue'),
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.request.isGettingRequestDetail,
    }),
    ...mapGetters(['requestDetail']),
    requestId() {
      const { query } = this.$route
      return query.id ? query.id : ''
    },
    agentId() {
      const { query } = this.$route
      return query.agent ? query.agent : ''
    },
    isApproved() {
      const { status } = this.requestDetail
      return status === 2
    },
    requestStatus() {
      const { status } = this.requestDetail
      if (status === 0) {
        return 'รอการอนุมัติ'
      } else if (status === 1) {
        return 'ไม่อนุมัติ'
      } else if (status === 2) {
        return 'อนุมัติ'
      }
      return status
    },
    accountType() {
      const { accountType = 0 } = this.requestDetail
      if (accountType === 1) {
        return 'Owner'
      } else if (accountType === 2) {
        return 'Provider'
      } else if (accountType === 3) {
        return 'Operator'
      }
      return 'ไม่มีข้อมูล'
    },
    whitelist() {
      const { whitelistIp } = this.requestDetail
      return whitelistIp?.split(',') || ['']
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['getRequestDetail']),
    fetchData() {
      this.getRequestDetail({
        id: this.requestId,
        agentId: this.agentId,
      })
    },
    onClickEditAgent() {
      this.$bvModal.show('edit-agent-modal')
    },
  },
}
</script>
